<!-- 样本模块 样本列表-->
<template>
  <div>
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号">
        <el-input size="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入用户名或手机号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>

      <el-form-item label="试管编号"
                    label-width="108px">
        <el-input size="medium"
                  v-model="queryParams.id"
                  clearable
                  placeholder="请输入试管编号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>

      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="number"
                           align="center"
                           label="试管编号"></el-table-column>
          <el-table-column prop="phone"
                           align="center"
                           label="手机号">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="queryWarehouseHandle(scope.row.phone)">{{scope.row.phone}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="userName"
                           align="center"
                           label="用户名">

          </el-table-column>

          <el-table-column prop="type"
                           align="center"
                           label="样本类型">
            <template slot-scope="scope">
              <span v-show="scope.row.type==1">血液</span>
              <span v-show="scope.row.type==2">尿液</span>
              <span v-show="scope.row.type==3">唾液</span>
            </template>
          </el-table-column>
          <el-table-column prop="name"
                           align="center"
                           label="受检者"></el-table-column>
          <el-table-column label="操作"
                           align="center">
            <template v-slot="{row}">
              <el-button-group>
                <el-button type="text"
                           size="mini"
                           icon="el-icon-tickets"
                           @click="gotoSampleInfo(row.orderId)">详情</el-button>
              </el-button-group>
            </template>

          </el-table-column>
        </el-table>
      </template>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getSampleList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        id: '',
        status: ''
      },
      tableData: [],
      total: 0,
      authAttr: ''
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getSampleList()
    this.getAuthAttr()
  },
  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getSampleList()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-6')
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getSampleList() {
      const { data: res } = await this.$http.get('/admin/sample/getSampleList', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    // 跳转详情
    gotoSampleInfo(id) {
      this.$router.push({ path: 'particulars', query: { orderId: id, pageNum: this.queryParams.pageNum, particularsType: 2 } })
    },
    queryWarehouseHandle(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, type: 2 } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>